<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            360 Clash
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to 360 Clash! 👋🏻
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account to join tournaments and show them who
          is the best!
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          v-slot="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                v-slot="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <spinning-button
              variant="primary"
              type="submit"
              block="block"
              :disabled="invalid"
              :is-loading="isLoading"
            >
              Sign in
            </spinning-button>
          </b-form>
        </validation-observer>

        <dismissible-message-box
          class="mt-1"
          :variant="MessageType.ERROR"
          :show="showErrorNotifications"
          :auto-dismiss="5000"
        >
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          {{ errors[0] }}
        </dismissible-message-box>

        <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{ name: 'auth-register' }">
            <span>Create an account</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import DismissibleMessageBox from '@/components/DismissibleMessageBox.vue'
import MessageType from '@/constants/MessageType'
import SpinningButton from '@/components/SpinningButton.vue'
import jwt from '@/auth/jwt/useJwt'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    SpinningButton,
    DismissibleMessageBox,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      password: '',
      userEmail: '',
      status: false,
      required,
      email,

      // error notification box
      errors: [],
      showErrorNotifications: false,

      // submit button
      isLoading: false,
    }
  },
  computed: {
    MessageType() {
      return MessageType
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async login() {
      this.errors = []
      this.showErrorNotifications = false

      const formValid = this.$refs.loginForm.validate()
      if (!formValid) {
        return
      }

      this.isLoading = true

      const { errors } = await jwt.login(
        this.userEmail,
        this.password,
      )

      this.isLoading = false

      if (errors && errors.length > 0) {
        if (errors[0]?.extensions?.code === 'AUTH_EMAIL_NOT_VERIFIED') {
          this.errors.push('Your account is not verified! Please check your email inbox and spam folder for the verification email.')
        } else if (errors[0]?.extensions?.code === 'AUTH_INVALID_CREDENTIALS') {
          this.errors.push('Wrong login credentials! Please try again.')
        } else if (errors[0]?.extensions?.code === 'AUTH_USER_LOCKED_OUT') {
          this.errors.push('This account was locked by administrators. Please contact our support for more information.')
        } else {
          this.errors.push('There was a problem logging in. Please try again or contact our support.')
        }

        this.showErrorNotifications = true

        return
      }

      await this.$store.dispatch('user/fetchMe')

      await this.$router.push(this.$route.query.redirect || '/')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
