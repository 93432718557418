var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v(" 360 Clash ")])]), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Welcome to 360 Clash! 👋🏻 ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Please sign-in to your account to join tournaments and show them who is the best! ")]), _c('validation-observer', {
    ref: "loginForm",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('b-form', {
          staticClass: "auth-login-form mt-2",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.login($event);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "email",
            "label": "Email"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                attrs: {
                  "id": "email",
                  "name": "login-email",
                  "state": errors.length > 0 ? false : null,
                  "placeholder": "john@example.com",
                  "autofocus": ""
                },
                model: {
                  value: _vm.userEmail,
                  callback: function callback($$v) {
                    _vm.userEmail = $$v;
                  },
                  expression: "userEmail"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('label', {
          attrs: {
            "for": "password"
          }
        }, [_vm._v("Password")]), _c('b-link', {
          attrs: {
            "to": {
              name: 'auth-forgot-password'
            }
          }
        }, [_c('small', [_vm._v("Forgot Password?")])])], 1), _c('validation-provider', {
          attrs: {
            "name": "Password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-input-group', {
                staticClass: "input-group-merge",
                class: errors.length > 0 ? 'is-invalid' : null
              }, [_c('b-form-input', {
                staticClass: "form-control-merge",
                attrs: {
                  "id": "password",
                  "type": _vm.passwordFieldType,
                  "state": errors.length > 0 ? false : null,
                  "name": "login-password",
                  "placeholder": "⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
                },
                model: {
                  value: _vm.password,
                  callback: function callback($$v) {
                    _vm.password = $$v;
                  },
                  expression: "password"
                }
              }), _c('b-input-group-append', {
                attrs: {
                  "is-text": ""
                }
              }, [_c('feather-icon', {
                staticClass: "cursor-pointer",
                attrs: {
                  "icon": _vm.passwordToggleIcon
                },
                on: {
                  "click": _vm.togglePasswordVisibility
                }
              })], 1)], 1), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })], 1), _c('b-form-group', [_c('b-form-checkbox', {
          attrs: {
            "id": "remember-me",
            "name": "checkbox-1"
          },
          model: {
            value: _vm.status,
            callback: function callback($$v) {
              _vm.status = $$v;
            },
            expression: "status"
          }
        }, [_vm._v(" Remember Me ")])], 1), _c('spinning-button', {
          attrs: {
            "variant": "primary",
            "type": "submit",
            "block": "block",
            "disabled": invalid,
            "is-loading": _vm.isLoading
          }
        }, [_vm._v(" Sign in ")])], 1)];
      }
    }])
  }), _c('dismissible-message-box', {
    staticClass: "mt-1",
    attrs: {
      "variant": _vm.MessageType.ERROR,
      "show": _vm.showErrorNotifications,
      "auto-dismiss": 5000
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "InfoIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.errors[0]) + " ")], 1), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('span', [_vm._v("New on our platform? ")]), _c('b-link', {
    attrs: {
      "to": {
        name: 'auth-register'
      }
    }
  }, [_c('span', [_vm._v("Create an account")])])], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }